import { Link } from 'gatsby';
import React from 'react';
import { css } from '@emotion/core';
import docupop_logo from '../images/docupop_logo.png';

const headerCSS = css`
  margin: 0 auto;
  max-width: 960px;
  padding: 13px 1.0875rem 24px;
  display: flex;
  justify-content: space-between;

  a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
  }

  h3 {
    margin-bottom: 0;
  }

  p {
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 500;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;

    img {
      margin: auto;
    }
  }
`;

const iconCSS = css`
  font-size: 20px;
  color: #fff;
  margin: 0 5px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`;

const Header = ({ showPhone = true }) => (
  <header
    style={{
      background: '#517bfe',
      maxWidth: 960,
      margin: 'auto',
    }}
  >
    <div css={headerCSS}>
      <a href="https://www.docupop.com">
        <img
          src={docupop_logo}
          style={{ width: '200px', margin: 'auto' }}
          alt="DocuPop"
        />
      </a>
      {showPhone && (
        <div>
          <p style={{ fontSize: 14 }}>Speak with a Student Loan Advisor now</p>
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'center',
            }}
          >
            <i css={iconCSS} className="fas fa-phone" />
            <h3>
              <a href="tel:1-855-910-6986">(855) 910-6986</a>
            </h3>
          </div>
        </div>
      )}
    </div>
  </header>
);

export default Header;

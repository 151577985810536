/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { css } from '@emotion/core';

import Header from './header';
import './layout.css';

const mainWrapCSS = css`
  -webkit-box-shadow: 0px 7px 7px 0px rgba(224, 224, 224, 0.86);
  -moz-box-shadow: 0px 7px 7px 0px rgba(224, 224, 224, 0.86);
  box-shadow: 0px 7px 7px 0px rgba(224, 224, 224, 0.86);
`;

type Props = {
  children?: React.ReactNode[];
  location?: any;
  showHeaderPhone?: boolean;
};
const Layout: React.FC<Props> = React.memo(
  ({ children, showHeaderPhone = true }) => {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Header showPhone={showHeaderPhone} />
            <div
              style={{
                margin: `0 auto`,
                maxWidth: 960,
                padding: `0`,
              }}
            >
              <main css={mainWrapCSS}>{children}</main>
              <footer>
                {/* © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a> */}
              </footer>
            </div>
          </>
        )}
      />
    );
  }
);

export default Layout;
